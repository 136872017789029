import React, { useContext } from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import * as commonStyles from "~animations/__layers__/mock_ui_components/styles.css";
import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { PageQueryContext } from "~context/PageQueryContext";

import { SvgIllustrationWithdrawFs } from "../../__layers__/SvgIllustrationWithdrawFs";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationIllustrationWithdrawFsProps
  extends StoryblokBlok,
    BoxProps {
  amount?: number;
  title?: string;
  textWithdraw?: string;
}

export function AnimationIllustrationWithdrawFs({
  amount,
  title,
  textWithdraw,
  className: userClassName,
  ...rest
}: AnimationIllustrationWithdrawFsProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        display="flex"
        className={clsx(
          userClassName,
          variantBackgroundColor.background_white,
          commonStyles.floatingUiBg
        )}
        borderRadius="lg"
        padding="spacing3"
        maxWidth="gridSpan4"
        width="100%"
        flexDirection="column"
        gap="spacing1"
        boxShadow="light"
        {...rest}
      >
        <Box
          textAppearance="body_sm"
          color="text_highContrast"
          data-motion={ANIMATED.secondary}
        >
          {title}
        </Box>

        {amount && (
          <Box textAppearance="h4" data-motion={ANIMATED.secondary}>
            {formatCurrencyString({ amount, lang })}
          </Box>
        )}
        <SvgIllustrationWithdrawFs
          width="100%"
          data-motion={ANIMATED.secondary}
        />
        <AnimLayerFakeButton
          ctaIcon="faArrowDownToLine"
          data-motion={ANIMATED.secondary}
        >
          {textWithdraw}
        </AnimLayerFakeButton>
      </AnimatedElement>
    </StoryblokEditable>
  );
}
