import * as React from "react";
import { useContext } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";
import { getSprinkles } from "~styles/getSprinkles.css";
import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { PageQueryContext } from "~context/PageQueryContext";

import * as commonStyles from "../styles.css";
import * as styles from "./styles.css";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export interface AnimLayerExpenditureChartProps
  extends Omit<GetSprinklesArgs, "color"> {
  className?: string;
  chartLabel: string;
  chartValue: number;
}

export function AnimLayerExpenditureChart({
  className: userClassName,
  chartLabel,
  chartValue,
  ...rest
}: AnimLayerExpenditureChartProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);
  const { pageContext } = useContext(PageQueryContext);
  const { lang } = pageContext || {};

  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        boxShadow="light"
        className={clsx(
          styles.container,
          variantBackgroundColor.background_white,
          commonStyles.floatingUiBg,
          userClassName,
          getSprinkles(atomProps)
        )}
        {...otherProps}
      >
        <svg
          className={styles.donut}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0.25 -0.75 152.75 152.77"
        >
          <circle
            fill={colorTokens_latest.Primary.Lavender[150].value}
            cx="77"
            cy="76"
            r="76"
          />
          <path
            fill={colorTokens_latest.Primary.Lavender[100].value}
            stroke={colorTokens_latest.Primary.Lavender[100].value}
            strokeWidth=".5"
            d="M77 0a76 76 0 1 1-31.548 6.857L77 76V0Z"
          />
          <path
            fill={colorTokens_latest.Primary.Lavender[30].value}
            stroke={colorTokens_latest.Primary.Lavender[30].value}
            strokeWidth=".5"
            d="M77 0a76 76 0 1 1-60.765 30.353L77 76V0Z"
          />
          <path
            fill={colorTokens_latest.Primary.Charcoal[100].value}
            d="M77 0a75.997 75.997 0 0 1 71.152 49.29 76.007 76.007 0 0 1 2.547 45.271A75.999 75.999 0 0 1 1.685 65.821L77 76V0Z"
          />
          <path
            stroke={colorTokens_latest.Primary.Pure_white[100].value}
            strokeWidth="1.5"
            d="m45 7 9 17m-38 6 16 12M1 66l19 2"
          />
          <path
            fill={colorTokens_latest.Primary.Charcoal[30].value}
            stroke={colorTokens_latest.Primary.Charcoal[30].value}
            strokeWidth=".5"
            d="M77 0a76 76 0 1 1-38.646 141.441L77 76V0Z"
          />
          <circle cx="77" cy="76" r="58" fill="#fff" />
          <path
            stroke={colorTokens_latest.Primary.Pure_white[100].value}
            strokeWidth="1.5"
            d="M77 0v18M38 142l10-16"
          />
        </svg>

        <div className={styles.labelContainer} data-motion={ANIMATED.secondary}>
          <span className={styles.labelText}>{chartLabel}</span>
          {chartValue && (
            <span className={styles.labelValue}>
              {formatCurrencyString({
                amount: chartValue,
                lang,
              })}
            </span>
          )}
        </div>
      </AnimatedElement>
    </StoryblokEditable>
  );
}
