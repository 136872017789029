import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import * as commonStyles from "~animations/__layers__/mock_ui_components/styles.css";
import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { SvgIllustrationSignupRate } from "../../__layers__/SvgIllustrationSignupRate";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationIllustrationSignupRateProps
  extends StoryblokBlok,
    BoxProps {
  title?: string;
}

export function AnimationIllustrationSignupRate({
  title,
  className: userClassName,
  ...rest
}: AnimationIllustrationSignupRateProps) {
  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        alignItems="center"
        borderRadius="lg"
        className={clsx(
          userClassName,
          variantBackgroundColor.background_white,
          commonStyles.floatingUiBg
        )}
        display="flex"
        flexDirection="column"
        maxWidth="gridSpan4"
        padding="spacing3"
        textAlign="center"
        width="100%"
        boxShadow="light"
        {...rest}
      >
        <Box
          textAppearance="h5"
          fontWeight="bold"
          marginBottom="spacing4"
          data-motion={ANIMATED.secondary}
        >
          {title}
        </Box>
        <SvgIllustrationSignupRate
          width="100%"
          data-motion={ANIMATED.secondary}
        />
      </AnimatedElement>
    </StoryblokEditable>
  );
}
