import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";
import { AnimationPaymentsListItemStatus } from "~animations/in_body_illustrations/AnimationPaymentsList/AnimationPaymentsListItemStatus";

import * as commonStyles from "../__layers__/mock_ui_components/styles.css";
import * as styles from "./styles.css";

import type { StatusOption } from "~animations/in_body_illustrations/AnimationPaymentsList/statuses";
import type { StoryblokBlok } from "~types/storyblok.types";

interface AnimationIdentityListItem {
  label: string;
  value: string;
  icon: StatusOption;
}

export interface AnimIdentityDetailsProps extends StoryblokBlok {
  title: string;
  listItems: Array<StoryblokBlok & AnimationIdentityListItem>;
  className?: string;
}

export function AnimIdentityDetails({
  title,
  listItems,
  className: userClassName,
  ...rest
}: AnimIdentityDetailsProps) {
  return (
    <AnimatedElement
      animationLevel="primary"
      display="flex"
      flexDirection="column"
      gap="spacing1.5"
      paddingY="spacing4"
      paddingX="spacing3"
      width="100%"
      maxWidth="gridSpan4"
      borderRadius="lg"
      boxShadow="light"
      className={clsx(
        userClassName,
        variantBackgroundColor.background_white,
        commonStyles.floatingUiBg
      )}
      {...rest}
    >
      <Box
        textAppearance="h5"
        fontWeight="bold"
        textAlign="center"
        marginBottom="spacing4"
        data-motion={ANIMATED.secondary}
      >
        {title}
      </Box>
      {listItems &&
        listItems.map((item) => {
          return (
            <Box
              display="flex"
              gap="spacing1.5"
              flexDirection="row"
              key={item._uid}
              alignItems="center"
              height="spacing3"
              textAppearance="body_md"
              data-motion={ANIMATED.secondary}
            >
              <Box width="25%" whiteSpace="nowrap">
                {item.label}
              </Box>
              <Box
                as="span"
                className={styles.tag}
                paddingY="spacing0"
                paddingX="spacing1"
              >
                {item.value}
              </Box>
              <Box marginLeft="auto" width="spacing3">
                <AnimationPaymentsListItemStatus status={item.icon} />
              </Box>
            </Box>
          );
        })}
    </AnimatedElement>
  );
}
