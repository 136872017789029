import React, { useContext } from "react";

import { faCheck } from "@fortawesome/sharp-regular-svg-icons/faCheck";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerUiCenteredText } from "~animations/__layers__/mock_ui_components/AnimLayerUiCenteredText";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import { PageQueryContext } from "~context/PageQueryContext";

import * as styles from "./styles.css";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import type { AnimLayerUiCenteredTextProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiCenteredText";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

interface PaymentSuccessDetail extends StoryblokBlok, BoxProps {
  textLeft: string;
  textRight: string;
}
export interface AnimMockUiSuccessProps extends StoryblokBlok, BoxProps {
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  animLayerUiCenteredText: Array<StoryblokBlok & AnimLayerUiCenteredTextProps>;
  details: Array<PaymentSuccessDetail>;
}

export function AnimMockUiSuccess({
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerUiCenteredText: animLayerUiCenteredTextBlokArray,
  details,
  ...rest
}: AnimMockUiSuccessProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerUiCenteredText] = animLayerUiCenteredTextBlokArray || [];

  const nonNumericCharacters = /[^\d.-]/g;

  return (
    <AnimatedMockUiWrapper {...rest}>
      <IconFontAwesome
        className={styles.iconStyle}
        icon={faCheck}
        marginX="auto"
        data-motion={ANIMATED.secondary}
        size="xl"
      />

      {animLayerUiCenteredText && (
        <AnimLayerUiCenteredText
          marginX="phoneSpacing2"
          {...animLayerUiCenteredText}
        />
      )}

      <Box marginX="phoneSpacing2">
        {details &&
          details.map((detail) => {
            return (
              <StoryblokEditable key={detail.textLeft} {...detail}>
                <Box
                  data-motion={ANIMATED.secondary}
                  display="flex"
                  alignItems="center"
                  textAppearance="phone_text_sm"
                  marginBottom="phoneSpacing1"
                  justifyContent="space-between"
                  key={detail.textLeft}
                >
                  <Box color="text_lowContrast">{detail.textLeft}</Box>
                  <Box fontWeight="semibold" color="text_lowContrast">
                    {detail.textRight.includes("£")
                      ? formatCurrencyString({
                          amount: parseFloat(
                            /** strip out non-numeric characters */
                            detail.textRight.replace(nonNumericCharacters, "")
                          ),
                          lang,
                        })
                      : detail.textRight}
                  </Box>
                </Box>
              </StoryblokEditable>
            );
          })}
      </Box>

      {animLayerFakeButton && (
        <AnimLayerFakeButton
          marginTop="auto"
          marginBottom="phoneSpacing4"
          size="md"
          marginX="phoneSpacing2"
          {...animLayerFakeButton}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
