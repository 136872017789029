import React from "react";

import { faArrowDownToLine } from "@fortawesome/sharp-regular-svg-icons/faArrowDownToLine";
import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";
import { faPlus } from "@fortawesome/sharp-regular-svg-icons/faPlus";
import clsx from "clsx";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { getFakeButtonStyles } from "./styles.css";

import type { IconDefinition } from "@fortawesome/sharp-regular-svg-icons";
import type { ReactNode } from "react";
import type { BoxProps } from "~components/Box";

type IconMapKey = keyof typeof ICON_MAP;

const ICON_MAP: Record<string, IconDefinition> = {
  faArrowRightLong,
  faArrowDownToLine,
  faPlus,
} as const;

export interface AnimLayerFakeButtonProps extends Omit<BoxProps, "size"> {
  ctaIcon?: IconMapKey;
  children?: ReactNode;
  size?: "sm" | "md" | "lg";
  variant?: "primary" | "secondary" | "illustration";
}

export function AnimLayerFakeButton({
  className: userClassName,
  children,
  ctaIcon,
  size,
  variant,
  ...rest
}: AnimLayerFakeButtonProps) {
  const dynamicIcon = ctaIcon && ICON_MAP[ctaIcon];

  if (!children) return null;

  return (
    <StoryblokEditable {...rest}>
      <Box
        className={clsx(getFakeButtonStyles({ size, variant }), userClassName)}
        {...rest}
      >
        {children}
        {dynamicIcon && <IconFontAwesome icon={dynamicIcon} />}
      </Box>
    </StoryblokEditable>
  );
}
