import * as React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { vars } from "~styles/themes/theme.css";

import { getSprinkles } from "~styles/getSprinkles.css";
import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

interface SvgIllustrationPaymentRateProps
  extends Omit<GetSprinklesArgs, "color"> {
  className?: string;
}

export function SvgIllustrationPaymentRate({
  className: userClassName,
  ...rest
}: SvgIllustrationPaymentRateProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  return (
    <svg
      viewBox="0 0 274 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(userClassName, getSprinkles(atomProps))}
      {...otherProps}
    >
      <path
        d="M0 1h260M0 25h260M0 49h260M0 73h260M0 97h260M0 121h260"
        stroke={colorTokens_latest.Primary.Charcoal[30].value}
        strokeDasharray="6 6"
      />
      <path
        d="M0 145h260"
        stroke={colorTokens_latest.Primary.Charcoal[30].value}
      />
      <path
        d="M34.293 74.827C24.135 87.914 7.198 91.729 0 92v52h261V10.228c-13.29-7.912-36.012-3.511-44.332 3.32-8.319 6.83-13.798 27.654-33.846 33.774-17.038 2.78-26.992 7.352-44.971 22.672C114.643 89.77 100 87.5 89.903 81.12 73 62.5 47 65.5 34.293 74.827Z"
        fill="url(#a)"
        style={{
          mixBlendMode: "multiply",
        }}
        opacity={0.33}
      />
      <path
        d="M.962 90.38a1 1 0 1 0 .076 1.999L.962 90.38ZM30 78.5l.656.755L30 78.5Zm60.614 2.02-.666.746.666-.747Zm92.966-33.41-.244-.97.244.97Zm34.912-33.903-.635-.773.635.773ZM1.038 92.379c7.386-.279 16.955-2.128 29.618-13.124l-1.312-1.51C17.05 88.421 7.926 90.117.962 90.38l.076 1.999Zm29.618-13.124c18.555-16.114 41.9-13.503 59.292 2.01l1.331-1.492C73.271 63.71 48.79 60.86 29.344 77.745l1.312 1.51Zm59.292 2.01c4.435 3.957 10.908 6.169 19.187 4.868 8.252-1.296 18.27-6.074 29.92-16.014l-1.298-1.521c-11.481 9.796-21.167 14.34-28.932 15.56-7.738 1.215-13.595-.86-17.546-4.385l-1.331 1.493Zm49.107-11.146c11.557-9.86 17.767-14.077 23.523-16.48 2.878-1.201 5.67-1.96 9.013-2.735 3.327-.771 7.242-1.566 12.234-2.824l-.489-1.94c-4.975 1.254-8.799 2.028-12.196 2.816-3.382.783-6.3 1.572-9.332 2.838-6.065 2.531-12.475 6.928-24.051 16.804l1.298 1.521Zm44.77-22.039c10.388-2.617 15.714-8.361 20.208-14.837 4.511-6.497 8.075-13.493 15.094-19.264l-1.27-1.545c-7.304 6.005-11.133 13.426-15.466 19.669-4.349 6.265-9.317 11.585-19.055 14.038l.489 1.94Zm35.302-34.101c3.94-3.24 11.031-5.808 18.979-6.67 7.928-.859 16.527.003 23.45 3.434l.888-1.792c-7.364-3.649-16.373-4.516-24.554-3.63-8.161.884-15.681 3.535-20.033 7.113l1.27 1.545Z"
        fill={colorTokens_latest.Primary.Lavender[150].value}
      />
      <path
        stroke={vars.color.semantic.semantic_green}
        strokeWidth={1.093}
        strokeLinecap="round"
        d="M260.855 8.463v135.491"
      />
      <g filter="url(#b)">
        <circle
          cx={261}
          cy={9}
          r={8}
          fill={vars.color.semantic.semantic_green}
        />
        <circle
          cx={261}
          cy={9}
          r={7}
          stroke={vars.color.neutral.white}
          strokeWidth={2}
        />
      </g>
      <defs>
        <linearGradient
          id="a"
          x1={305.102}
          y1={8.634}
          x2={305.102}
          y2={148.567}
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={0}
            stopColor={colorTokens_latest.Primary.Lavender[150].value}
          />
          <stop
            offset={0.467}
            stopColor={colorTokens_latest.Primary.Lavender[100].value}
          />
          <stop offset={1} stopColor={vars.color.neutral.white} />
        </linearGradient>
        <filter
          id="b"
          x={248.629}
          y={1}
          width={24.741}
          height={24.741}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4.371} />
          <feGaussianBlur stdDeviation={2.185} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.0509804 0 0 0 0 0.0392157 0 0 0 0 0.172549 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3098_56811"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3098_56811"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
