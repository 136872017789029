import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimLayerBalanceCardItem } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardItem";
import * as commonStyles from "~animations/__layers__/mock_ui_components/styles.css";
import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import type { AnimLayerBalanceCardItemProps } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardItem";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationIllustrationAllAccountsProps extends StoryblokBlok {
  className?: string;
  linked?: string;
  accounts?: Array<StoryblokBlok & AnimLayerBalanceCardItemProps>;
}

export function AnimationIllustrationAllAccounts({
  className: userClassName,
  linked,
  accounts,
  ...rest
}: AnimationIllustrationAllAccountsProps) {
  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        className={clsx(userClassName)}
        width="gridSpan4"
        {...rest}
      >
        {accounts && accounts[0] && (
          <AnimLayerBalanceCardItem
            {...accounts[0]}
            borderRadius="lg"
            boxShadow="light"
            className={clsx(
              variantBackgroundColor.background_white,
              commonStyles.floatingUiBg
            )}
          />
        )}
        <Box opacity="0.5">
          <Box
            data-motion="secondary"
            textAppearance="body_sm"
            paddingTop="spacing2"
            paddingBottom="spacing1"
            marginLeft="spacing3"
            fontWeight="semibold"
          >
            {linked}
          </Box>
          <Box marginX="spacing3">
            {accounts && accounts[1] && (
              <AnimLayerBalanceCardItem
                {...accounts[1]}
                className={clsx(
                  variantBackgroundColor.background_white,
                  commonStyles.floatingUiBg
                )}
                borderRadius="lg"
                boxShadow="light"
                variant="illustration"
              />
            )}
            <Box marginX="spacing3" marginTop="spacing1">
              {accounts && accounts[2] && (
                <AnimLayerBalanceCardItem
                  {...accounts[2]}
                  className={clsx(
                    variantBackgroundColor.background_white,
                    commonStyles.floatingUiBg
                  )}
                  borderRadius="lg"
                  boxShadow="light"
                  variant="illustration"
                />
              )}
            </Box>
          </Box>
        </Box>
      </AnimatedElement>
    </StoryblokEditable>
  );
}
