import React from "react";

import { faBolt } from "@fortawesome/sharp-regular-svg-icons/faBolt";
import { faBuildingColumns } from "@fortawesome/sharp-regular-svg-icons/faBuildingColumns";
import { faCalendar } from "@fortawesome/sharp-regular-svg-icons/faCalendar";
import { faCheckCircle } from "@fortawesome/sharp-regular-svg-icons/faCheckCircle";
import { faFingerprint } from "@fortawesome/sharp-regular-svg-icons/faFingerprint";
import { faPlus } from "@fortawesome/sharp-regular-svg-icons/faPlus";
import { faShieldCheck } from "@fortawesome/sharp-regular-svg-icons/faShieldCheck";
import { faStopwatch } from "@fortawesome/sharp-regular-svg-icons/faStopwatch";
import { faWallet } from "@fortawesome/sharp-regular-svg-icons/faWallet";
import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import * as commonStyles from "../styles.css";
import * as styles from "./styles.css";

import type { IconDefinition } from "@fortawesome/sharp-regular-svg-icons";
import type { BoxProps } from "~components/Box";
import type { EnabledLanguageIsoCode } from "~config/ENABLED_LANGUAGES.config";

type IconMapKey = keyof typeof ICON_MAP;

const ICON_MAP: Record<string, IconDefinition> = {
  faBolt,
  faPlus,
  faBuildingColumns,
  faCalendar,
  faWallet,
  faCheckCircle,
  faFingerprint,
  faShieldCheck,
  faStopwatch,
} as const;

export interface AnimLayerNotificationProps extends BoxProps {
  icon?: IconMapKey;
  title?: string;
  description?: string;
  lang?: EnabledLanguageIsoCode;
  currency?: string;
}

export function AnimLayerNotification({
  icon = "faCheckCircle",
  title,
  className: userClassName,
  description,
  ...rest
}: AnimLayerNotificationProps) {
  const dynamicIcon = ICON_MAP[icon];

  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        {...rest}
        maxWidth="gridSpan4"
        display="flex"
        alignItems="center"
        padding="phoneSpacing1"
        paddingRight="phoneSpacing2"
        gap="phoneSpacing1"
        width="max-content"
        borderRadius="md"
        className={clsx(
          userClassName,
          variantBackgroundColor.background_white,
          commonStyles.floatingUiBg
        )}
        {...rest}
      >
        {dynamicIcon && (
          <IconFontAwesome
            borderRadius="sm"
            className={styles.iconStyle}
            color="white"
            display="block"
            icon={dynamicIcon}
            marginX="auto"
          />
        )}

        <Box paddingRight="phoneSpacing2">
          {title && (
            <Box textAppearance="phone_text_md" fontWeight="semibold">
              {title}
            </Box>
          )}
          {description && (
            <Box color="text_lowContrast" textAppearance="phone_text_md">
              {description}
            </Box>
          )}
        </Box>
      </AnimatedElement>
    </StoryblokEditable>
  );
}
