import * as React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";
import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

interface SvgIllustrationDepositProps extends Omit<GetSprinklesArgs, "color"> {
  className?: string;
}

export function SvgIllustrationDeposit({
  className: userClassName,
  ...rest
}: SvgIllustrationDepositProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  return (
    <svg
      viewBox="0 0 275 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(userClassName, getSprinkles(atomProps))}
      {...otherProps}
    >
      <rect x={0.6} y={0.6} width={62.8} height={62.8} rx={15.4} />
      <rect
        x={0.6}
        y={0.6}
        width={62.8}
        height={62.8}
        rx={15.4}
        stroke={colorTokens_latest.Primary.Charcoal[10].value}
        strokeWidth={1.2}
      />
      <g
        clipPath="url(#a)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={colorTokens_latest.Primary.Charcoal[100].value}
      >
        <path d="M27.8662 29.7139L21.0088 36.5707L23.2946 38.8565L30.152 31.9997L27.8662 29.7139ZM30.152 45.7139L32.4378 47.9997C37.7709 42.6665 43.1046 37.3328 48.4378 31.9997L46.152 29.7139C40.8188 35.047 35.4851 40.3807 30.152 45.7139ZM39.2946 31.9997L37.0088 29.7139L25.5804 41.1423L27.8662 43.4281C31.6757 39.6186 35.4851 35.8085 39.2946 31.9997Z" />
        <path d="M16.4375 32L18.7233 34.2858C23.2949 29.7142 27.8659 25.1432 32.4375 20.5716L41.5801 29.7142L43.8659 27.4284L32.4375 16C27.1038 21.3331 21.7706 26.6663 16.4375 32Z" />
      </g>
      <rect
        x={211.6}
        y={0.6}
        width={62.8}
        height={62.8}
        rx={15.4}
        fill="#EC0000"
      />

      <path
        d="M264.457 40.5645C264.457 46.8704 255.045 51.9528 243.563 51.9528C232.08 51.9528 222.668 46.8704 222.668 40.5645C222.668 36.0469 227.468 32.1881 234.527 30.3057C234.433 31.9998 234.81 33.6939 235.751 35.1998L242.339 46.6822C242.81 47.5292 243.186 48.4704 243.374 49.4116L243.563 48.941C245.163 46.1175 245.163 42.6351 243.563 39.8116L238.292 30.6822C236.692 27.8586 236.692 24.3763 238.292 21.6469L238.574 21.1763C238.763 22.1175 239.139 23.0586 239.61 23.9057L242.715 29.1763L247.515 37.4586C247.986 38.3057 248.363 39.2469 248.551 40.1881L248.833 39.7175C250.433 36.8939 250.433 33.4116 248.833 30.5881L243.563 21.6469C241.963 18.8233 241.963 15.341 243.563 12.5175L243.845 12.0469C244.033 12.9881 244.41 13.9292 244.88 14.7763L251.468 26.1645C252.221 27.4822 252.598 28.8939 252.692 30.3057C259.657 32.1881 264.457 36.0469 264.457 40.5645Z"
        fill="white"
      />
      <rect
        x={211.6}
        y={0.6}
        width={62.8}
        height={62.8}
        rx={15.4}
        stroke={colorTokens_latest.Primary.Charcoal[10].value}
        strokeWidth={1.2}
      />
      <circle
        cx={112}
        cy={33}
        r={4}
        fill={colorTokens_latest.Primary.Charcoal[10].value}
      />
      <circle
        cx={100}
        cy={33}
        r={4}
        fill={colorTokens_latest.Primary.Charcoal[30].value}
      />
      <circle
        cx={88}
        cy={33}
        r={4}
        fill={colorTokens_latest.Primary.Charcoal[50].value}
      />
      <circle
        cx={76}
        cy={33}
        r={4}
        fill={colorTokens_latest.Primary.Charcoal[80].value}
      />
      <circle
        cx={163}
        cy={33}
        r={4}
        fill={colorTokens_latest.Primary.Charcoal[10].value}
      />
      <circle
        cx={175}
        cy={33}
        r={4}
        fill={colorTokens_latest.Primary.Charcoal[30].value}
      />
      <circle
        cx={187}
        cy={33}
        r={4}
        fill={colorTokens_latest.Primary.Charcoal[50].value}
      />
      <circle
        cx={199}
        cy={33}
        r={4}
        fill={colorTokens_latest.Primary.Charcoal[80].value}
      />
      <path
        d="m123.252 41.23 13.31 7.58c1.128.674 1.641.201 2.105-.006l14.118-8.1c.502-.32.848-.595.848-1.159 0-.564-.428-.848-.684-1.007L149.147 36l3.614-2.097c.586-.293 1.209-.953 1.209-1.78v-5.584c0-1.451-.831-1.905-1.229-2.124l-13.852-7.995c-1.199-.792-2.179-.27-2.584 0l-13.895 8.078c-.624.424-.822.88-.818 1.34.006.695.723 1.22.821 1.285l3.609 2.145-3.835 2.337c-.601.366-.643 1.048-.643 1.048-.044.305-.044 5.442-.044 5.442 0 1.348.123 2.227 1.752 3.134Zm27.874-1.574-13.528 7.574-13.077-7.37 4.102-2.354 8.488 4.854c.257.184.721.17.963.039l9.156-5.299 3.896 2.556Zm1.15-8.084-3.654-2.247 3.643-2.168.011 4.415Zm-13.674-13.06 12.459 7.265-4.088 2.5-8.38-5.08.009-4.686Zm-1.007 6.28 7.559 4.583-7.559 4.363H137.586l-7.728-4.463 7.728-4.484h.009Zm-13.692 1.041 12.707-7.338v4.703l-8.819 4.982-3.888-2.347Zm4.017 4.567 9.178 5.297a1.005 1.005 0 0 0 .964.006l8.817-5.284 3.985 2.418-13.279 7.585-13.517-7.787 3.852-2.235Zm-4.628 4 3.555 2.03-3.555 2.065V34.4Z"
        fill={colorTokens_latest.Primary.Charcoal[100].value}
      />
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(16 16)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
