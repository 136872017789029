import * as React from "react";

export function StatusProgress() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <path
        d="M1.5 8.54102C1.5 4.95116 4.41015 2.04102 8 2.04102H23.9999C27.5898 2.04102 30.4999 4.95117 30.4999 8.54102V24.541C30.4999 28.1308 27.5898 31.041 23.9999 31.041H8C4.41015 31.041 1.5 28.1308 1.5 24.541V8.54102Z"
        stroke="#EBEAFE"
        strokeWidth="3"
        strokeMiterlimit="6.07152"
      />
      <path
        d="M22 12.541V20.541C22 21.6348 21.0937 22.541 20 22.541H12C10.875 22.541 10 21.6348 10 20.541V12.541C10 11.4473 10.875 10.541 12 10.541H20C21.0937 10.541 22 11.4473 22 12.541Z"
        fill="#5D5DD5"
      />
      <path
        d="M3 24.541C3 27.3024 5.23858 29.541 8 29.541H24C26.7614 29.541 29 27.3024 29 24.541V8.54102C29 5.77959 26.7614 3.54102 24 3.54102H16V0.541016H24C28.4183 0.541016 32 4.12274 32 8.54102V24.541C32 28.9593 28.4183 32.541 24 32.541H8C3.58172 32.541 0 28.9593 0 24.541V16.541H3V24.541Z"
        fill="#5D5DD5"
      />
    </svg>
  );
}
