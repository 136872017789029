import * as React from "react";
import { useContext } from "react";

import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { PageQueryContext } from "~context/PageQueryContext";

import { AnimLayerTransactionListItem } from "../AnimLayerTransactionListItem";
import { AnimLayerUiCardTitle } from "../AnimLayerUiCardTitle";

import type { AnimLayerTransactionListItemProps } from "../AnimLayerTransactionListItem";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerTransactionListProps extends Omit<BoxProps, "color"> {
  ctaText?: string;
  iconBackgroundColor?: "dark" | "light";
  isDateVisible?: boolean;
  isStatusVisible?: boolean;
  title?: string;
  transactions: Array<
    StoryblokBlok & Omit<AnimLayerTransactionListItemProps, "index">
  >;
}

const SWATCH_COLORS = [
  colorTokens_latest.Primary.Charcoal[30].value,
  colorTokens_latest.Primary.Lavender[150].value,
  colorTokens_latest.Primary.Lavender[100].value,
  colorTokens_latest.Primary.Charcoal[100].value,
  colorTokens_latest.Primary.Lavender[30].value,
];

export function AnimLayerTransactionList({
  ctaText,
  iconBackgroundColor,
  isDateVisible,
  isStatusVisible,
  title,
  transactions,
  ...rest
}: AnimLayerTransactionListProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  return (
    <StoryblokEditable {...rest}>
      <Box overflow="hidden" paddingX="phoneSpacing3" paddingY="phoneSpacing2">
        <AnimLayerUiCardTitle title={title} ctaText={ctaText} />
        <Box textAppearance="phone_text_sm" display="grid" rowGap="spacing1.5">
          {Array.isArray(transactions) &&
            transactions.map((item, index) => {
              const swatchIndex = index % 5;
              const swatchColor = SWATCH_COLORS[swatchIndex];

              return (
                <AnimLayerTransactionListItem
                  index={index}
                  key={item._uid}
                  iconBackgroundColor={iconBackgroundColor}
                  isDateVisible={isDateVisible}
                  isStatusVisible={isStatusVisible}
                  swatchColor={swatchColor}
                  lang={lang}
                  {...item}
                />
              );
            })}
        </Box>
      </Box>
    </StoryblokEditable>
  );
}
