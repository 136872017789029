import React, { forwardRef, useEffect, useRef, useState } from "react";

import mergeRefs from "merge-refs";
import { inView } from "motion";

import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";
import { PhoneMockup } from "~components/PhoneMockup";
import { SectionHeader } from "~components/SectionHeader";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimationSlider } from "~animations/animation_primitives/AnimationSlider";
import { MOCK_UI_SCREENS } from "~animations/mock_ui/MockUiMap";

import {
  ANIMATED,
  getSingleElemByMotionAttr,
} from "../../utils/motion_one/motion_attribute_selectors";
import { SliderIndicator } from "../SliderIndicator";
import * as styles from "./styles.css";

import type { SliderIndicatorProps } from "../SliderIndicator/SliderIndicator";
import type { Ref } from "react";
import type { BoxProps } from "~components/Box";
import type { ButtonLinkProps } from "~components/ButtonLink";
import type { SectionHeaderProps } from "~components/SectionHeader";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type { StoryblokBlok } from "~types/storyblok.types";

interface StepProps {
  title: string;
  description?: string;
  ui: [StoryblokBlok];
}

export interface StepperProps extends BoxProps {
  steps: Array<StepProps>;
  indicator: [Omit<SliderIndicatorProps, "isCurrent" | "isPaused">];
  current: number;
  previous: number;
  stepChangeCallback?: (stringifiedIndex: `${number}`) => void;
  cta?: Array<StoryblokBlok & ButtonLinkProps>;
  header?: Array<StoryblokBlok & SectionHeaderProps>;
  stepTitleColorOverride?: VariantBackgroundColorEnum;
  headerColorOverride?: VariantBackgroundColorEnum;
}

export const Stepper = forwardRef(
  (
    {
      steps,
      indicator,
      current,
      previous,
      stepChangeCallback,
      cta,
      header: headerArray,
      stepTitleColorOverride: __stepTitleColorOverride, // TODO
      headerColorOverride,
      ...rest
    }: StepperProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [ctaButton] = cta || [];
    const [header] = headerArray || [];

    const stepperContainerRef = useRef<HTMLDivElement>(null);
    const mergedRefs = mergeRefs(ref, stepperContainerRef);
    const stepsNumber = steps.length;

    const [indicatorComponent] = indicator;
    const animationDurationSeconds = 3;
    const animMockUis = steps.map(({ ui }) => {
      return {
        animation: ui,
        _uid: ui[0]._uid,
      };
    });

    const [isPaused, setIsPaused] = useState(true);
    const [animationStarted, setAnimationStarted] = useState(false);

    const handlePointerDown = () => {
      setIsPaused(true);
    };
    const handlePointerUp = () => {
      setIsPaused(false);
    };

    useEffect(() => {
      const isStepperAnimationEnabled =
        localStorage.getItem("isStepperAnimationEnabled") === "true" ||
        window.location.hostname !== "localhost";

      let value = current;

      const intervalId = setInterval(() => {
        if (isPaused) return;
        value = (value + 1) % stepsNumber;
        stepChangeCallback?.(`${value}`);
      }, animationDurationSeconds * 1000);

      const stepsElement = getSingleElemByMotionAttr(
        stepperContainerRef,
        ANIMATED.steps
      );

      if (stepsElement) {
        inView(stepsElement, () => {
          if (!animationStarted && isStepperAnimationEnabled) {
            setIsPaused(false);
            setAnimationStarted(true);
          }

          return () => {
            setIsPaused(true);
            setAnimationStarted(false);
          };
        });
      }

      return () => {
        return clearInterval(intervalId);
      };
    }, [current, stepsNumber, stepChangeCallback, isPaused, animationStarted]);

    return (
      <Box ref={mergedRefs} data-component="stepper" {...rest}>
        <>
          <Box maxHeight="maxPhoneHeight" width="100%" position="relative">
            {header && (
              <StoryblokEditable>
                <SectionHeader
                  data-motion={ANIMATED.stepperDetails}
                  fontFamily="proxima"
                  textAppearance={header.textAppearance}
                  zIndex="2"
                  position="absolute"
                  top="0"
                  left="0"
                  opacity="0"
                  maxWidth="33%"
                  marginLeft="spacing1"
                  className={styles.header}
                  headerColorOverride={headerColorOverride}
                  {...header}
                />
              </StoryblokEditable>
            )}
            <PhoneMockup
              marginX="auto"
              position="relative"
              display="block"
              aspectRatio="mobile"
              justifyContent="center"
              maxHeight="maxPhoneHeight"
              maxWidth="phoneWidth"
              width="auto"
              onMouseDown={handlePointerDown}
              onMouseUp={handlePointerUp}
            >
              <AnimationSlider
                animationComponentMap={MOCK_UI_SCREENS}
                items={animMockUis}
                current={current}
                previous={previous}
              />
            </PhoneMockup>
            {ctaButton && (
              <ButtonLink
                data-motion={ANIMATED.stepperDetails}
                position="absolute"
                bottom="0"
                right="0"
                opacity="0"
                marginRight="spacing1"
                appearance="secondary"
                className={styles.cta}
                {...(ctaButton as ButtonLinkProps)}
                background="background_white"
              />
            )}
          </Box>

          <Box
            display="grid"
            __gridTemplateColumns={`repeat(${stepsNumber}, 1fr)`}
            data-motion={ANIMATED.steps}
          >
            {steps.map((step, index) => {
              const isCurrent = current === index;
              const onClickHandler = () => {
                stepChangeCallback?.(`${index}`);
              };

              return (
                <SliderIndicator
                  key={step.title}
                  title={step.title}
                  isCurrent={isCurrent}
                  onClick={onClickHandler}
                  isPaused={isPaused}
                  animationDuration={animationDurationSeconds}
                  className={styles.indicator({ isCurrent })}
                  {...indicatorComponent}
                />
              );
            })}
          </Box>
        </>
      </Box>
    );
  }
);
