import * as React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";
import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

interface SvgIllustrationSignupRateProps
  extends Omit<GetSprinklesArgs, "color"> {
  className?: string;
}

export function SvgIllustrationSignupRate({
  className: userClassName,
  ...rest
}: SvgIllustrationSignupRateProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  return (
    <svg
      viewBox="0 0 285 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(userClassName, getSprinkles(atomProps))}
      {...otherProps}
    >
      <path
        d="M0 9h285M0 33h285M0 57h285M0 81h285M0 105h285M0 129h285"
        stroke={colorTokens_latest.Primary.Charcoal[30].value}
        strokeDasharray="6 6"
      />
      <path
        d="M0 153h285"
        stroke={colorTokens_latest.Primary.Charcoal[30].value}
      />
      <g fill={colorTokens_latest.Primary.Lavender[150].value}>
        <path d="M15 111V59a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v52H15Z" />
        <path d="M47 66V42a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v24H47Z" />
        <path d="M79 86V34a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v52H79Z" />
        <path d="M111 66V42a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v24h-16Z" />
        <path d="M143 71V28a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v43h-16Z" />
        <path d="M175 74V16a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v58h-16Z" />
        <path d="M207 47V4a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v43h-16Z" />
        <path d="M255.5 54V11a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v43h-16Z" />
      </g>
      <g fill={colorTokens_latest.Primary.Lavender[100].value}>
        <path d="M15 121v-9h16v9H15Z" />
        <path d="M47 104V67h16v37H47Z" />
        <path d="M79 133V87h16v46H79Z" />
        <path d="M111 104V67h16v37h-16Z" />
        <path d="M143 112V72h16v40h-16Z" />
        <path d="M175 108V75h16v33h-16Z" />
        <path d="M255 108V55h16v53h-16Z" />
        <path d="M207 108V48h16v60h-16Z" />
      </g>
      <g fill={colorTokens_latest.Primary.Charcoal[30].value}>
        <path d="M15 153v-31h16v31H15Z" />
        <path d="M47 153v-48h16v48H47Z" />
        <path d="M79 153v-19h16v19H79Z" />
        <path d="M111 153v-48h16v48h-16Z" />
        <path d="M143 153v-40h16v40h-16Z" />
        <path d="M175 153v-44h16v44h-16Z" />
        <path d="M207 153v-44h16v44h-16Z" />
        <path d="M255 153v-44h16v44h-16Z" />
      </g>
      <path
        d="M239 2v151"
        stroke={colorTokens_latest.Primary.Lavender[100].value}
      />
    </svg>
  );
}
